import React from 'react';
import styled from 'styled-components';
import { QRCard } from './QRCard';

const Wrapper = styled.div``;

interface CardsList {
	cards: QRCard[];
	cardSize?: Size;
}

export const CardsList: React.FC<CardsList> = ({ cards, cardSize }) => {
	return <Wrapper>
		{cards.map((card, index) => {
			return <QRCard key={index} code={card.code} labels={card.labels} size={cardSize} />;
		})}
	</Wrapper>;
};
