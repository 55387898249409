import React, { ChangeEvent, FC, useState } from 'react';
import styled from 'styled-components';
import { TextField } from '@material-ui/core';
import { useAppContext } from '../contexts/AppContext';

const Wrapper = styled.div`

`;

export const SetupView: FC = () => {
	const { data, setRawDataText } = useAppContext();

	function onTextChange(ev: ChangeEvent<HTMLTextAreaElement>) {
		setRawDataText(ev.target.value);
	}

	return <Wrapper>
		<p>Insert data from the spreadsheet below:</p>
		<TextField value={data} fullWidth={true} multiline={true} variant={'outlined'} label={'Data'} onChange={onTextChange} />
	</Wrapper>;
};
