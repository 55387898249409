import React from 'react';

interface TabPanel {
	value: number;
	index: number;
}

export const TabPanel: React.FC<TabPanel> = ({ children, value, index }) => {
	return <>{value === index && children}</>;
};
