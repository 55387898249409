import React from 'react';
import styled from 'styled-components';
import { QRCode } from 'react-qr-svg';

const CodeCard = styled.div`
  padding: 2mm 0;
  display: inline-flex;
  flex-flow: column;
  align-items: center;
  //width: 5cm;
  //height: 5cm;
  margin: 2mm;
  box-sizing: border-box;
  border: 1px solid grey;
  page-break-inside: avoid;
`;

const TextArea = styled.div`
  margin-top: 1mm;
  line-height: 1;
`;


export const QRCard: React.FC<QRCard> = ({ labels, code, size }) => {
	const [width, height] = size || [50, 50];

	return (
		<CodeCard style={{ width: `${width}mm`, height: `${height}mm` }}>
			<QRCode level={'H'} value={code} />
			{labels.map(label => {
				return (
					<TextArea>
						{label.header}
						{label.content}
					</TextArea>
				);
			})}
		</CodeCard>
	);
};
