import React, { useMemo } from 'react';
import styled from 'styled-components';
import {
	Checkbox,
	FormControlLabel,
	Grid,
	InputAdornment,
	TextField
} from '@material-ui/core';
import { useAppContext } from '../contexts/AppContext';
import { CardsList } from './CardsList';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
`;

const PreviewArea = styled.div`
  display: flex;
  flex-flow: column;
`;

export const ConfigurationView: React.FC = () => {
	const { cards, firstRowIsHeader, setFirstRowIsHeader, cardSize, setCardWidth, setCardHeight } = useAppContext();
	const limitedCards = useMemo(() => cards.slice(0, 3), [cards]);

	const [cardWidth, cardHeight] = useMemo(() => cardSize, [cardSize]);

	return (
		<Wrapper>
			<Grid container={true} spacing={2} alignContent={'flex-start'}>
				<Grid item={true} xs={12}>
					<FormControlLabel
						control={
							<Checkbox
								checked={firstRowIsHeader}
								onChange={ev => setFirstRowIsHeader(ev.target.checked)}
							/>
						}
						label="First row is header"
					/>
				</Grid>

				<Grid item={true} xs={6}>
					<TextField
						label="Width"
						value={cardWidth}
						onChange={ev => setCardWidth(parseFloat(ev.target.value) || 0)}
						InputProps={{
							endAdornment: <InputAdornment position="start">mm</InputAdornment>
						}}
					/>
				</Grid>

				<Grid item={true} xs={6}>
					<TextField
						label="Height"
						value={cardHeight}
						onChange={ev => setCardHeight(parseFloat(ev.target.value) || 0)}
						InputProps={{
							endAdornment: <InputAdornment position="start">mm</InputAdornment>
						}}
					/>
				</Grid>
			</Grid>

			<PreviewArea>
				<CardsList cards={limitedCards} cardSize={cardSize} />
			</PreviewArea>
		</Wrapper>
	);
};
