import { createContext, FC, useContext, useMemo, useState } from 'react';
import { remapCards } from '../utils/card-remap';

interface AppContext {
	cards: QRCard[];
	data?: string;
	setRawDataText: (data: string) => void;

	firstRowIsHeader: boolean;
	setFirstRowIsHeader: (value: boolean) => void;

	cardSize: Size;
	setCardWidth: (width: number) => void;
	setCardHeight: (height: number) => void;
}

export const AppContext = createContext<AppContext>({} as AppContext);

export const AppContextProvider: FC = ({ children }) => {
	const [rawDataText, setRawDataText] = useState<string>('');
	const [firstRowIsHeader, setFirstRowIsHeader] = useState<boolean>(true);
	const [cardSize, setCardSize] = useState<[number, number]>([50, 50]);

	const cards = useMemo(() => remapCards(rawDataText, firstRowIsHeader), [
		rawDataText,
		firstRowIsHeader
	]);

	function setCardWidth(width: number) {
		setCardSize(([w, h]) => [width, h]);
	}

	function setCardHeight(height: number) {
		setCardSize(([w, h]) => [w, height]);
	}

	return <AppContext.Provider children={children} value={{
		cards,
		data: rawDataText,
		setRawDataText,

		firstRowIsHeader,
		setFirstRowIsHeader,

		cardSize,
		setCardWidth,
		setCardHeight
	}} />;
};

export const useAppContext = () => useContext(AppContext);
