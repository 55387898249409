import React from 'react';
import styled from 'styled-components';
import { useAppContext } from '../contexts/AppContext';
import { CardsList } from './CardsList';

const Wrapper = styled.div`
    
`;

export const PrintView: React.FC = () => {
	const {cards, cardSize} = useAppContext();

	return <Wrapper>
		<CardsList cards={cards} cardSize={cardSize}/>
	</Wrapper>;
};
