import { createGlobalStyle, css } from 'styled-components';

export const GlobalStyle = createGlobalStyle`	
	html, body {
		padding: 0;
		margin: 0;
		font-family: "Open Sans";
	}
	
	@page  {
		margin: 3mm;
		padding: 0;
  }
`;

export const hideInPrint = css`
  @media print {
    & {
      display: none !important;
    }
  }
`;
