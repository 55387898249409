import React, { useState } from 'react';
import styled from 'styled-components';
import { GlobalStyle, hideInPrint } from './GlobalStyle';
import {
	AppBar, Tab,
	Tabs
} from '@material-ui/core';
import { BrowserRouter as Router } from 'react-router-dom';
import { SetupView } from './Components/SetupView';
import { TabPanel } from "./Components/TabPanel";
import { ConfigurationView } from './Components/ConfigurationView';
import { PrintView } from './Components/PrintView';

const PanelContent = styled.div`
	margin: 32px;
`

const Wrapper = styled.div`
  min-height: 100vh;
`;

const StyledAppBar = styled(AppBar)`
  ${hideInPrint}
`;

const Grid = styled.div`
  display: inline-table;
  //display: flex;
  //flex-flow: row;
  //flex-wrap: wrap;
`;

function App() {
	const [page, setPage] = useState<number>(0);

	return (
		<Router>
			<Wrapper> 
				<StyledAppBar position="sticky">
					<Tabs value={page} onChange={(_, index) => setPage(index)} aria-label="simple tabs example">
						<Tab label="Data" />
						<Tab label="Configuration" />
						<Tab label="Print" />
					</Tabs>
				</StyledAppBar>


				<TabPanel value={page} index={0}>
					<PanelContent>
						<SetupView/>
					</PanelContent>
				</TabPanel>
				<TabPanel value={page} index={1}>
					<PanelContent>
						<ConfigurationView/>
					</PanelContent>
				</TabPanel>
				<TabPanel value={page} index={2}>
					<PanelContent>
						<PrintView/>
					</PanelContent>
				</TabPanel>
				<GlobalStyle />
			</Wrapper>
		</Router>
	);
}

export default App;
