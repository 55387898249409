export function remapCards(rawText: string, firstRowIsLabel: boolean = true): QRCard[] {
	const rows = rawText?.split('\n').filter(row => row !== '').map(row => row.split('\t'));

	if (firstRowIsLabel) {
		const headers = rows?.[0];
		const dataRows = rows?.slice(1);

		return dataRows.map(data => {
			const [code, ...rawLabels] = data;
			const labels = rawLabels.map((label, index) => ({ header: headers[index + 1], content: label }));
			labels.unshift({ header: headers[0], content: code });

			return {
				code: code,
				labels
			};
		});
	}

	return rows.map(data => {
		const [code, ...rawLabels] = data;
		const labels = rawLabels.map((label) => ({ content: label }));
		labels.unshift({ content: code });

		return {
			code: code,
			labels
		};
	});
}
